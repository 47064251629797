import { Platform } from 'react-native';

import Constants from 'expo-constants';
import * as Device from 'expo-device';

import API from 'app/util/api.json';

/**
 * Determine the local network IP if running in development.
 */
export const LOCAL_NETWORK = Constants.expoConfig.hostUri
  ? `http://${Constants.expoConfig.hostUri.replace(/:\d+$/, '')}`
  : window?.location?.hostname
  ? `http://${window.location.hostname}`
  : 'http://localhost'; // Fallback for unit tests

/**
 * Read the APP_ENV environment variable as defined during build time.
 * https://docs.expo.dev/eas-update/environment-variables/#creating-an-envts-file-to-get-environment
 */
export const ENVIRONMENT = Constants.expoConfig?.extra?.appEnv || 'development';

const withLocalNetwork = (url) =>
  url.replace('http://localhost', LOCAL_NETWORK);

export const CORE_API_URL = withLocalNetwork(API[ENVIRONMENT].core);

export const CARE_API_URL = withLocalNetwork(API[ENVIRONMENT].care);

export const MESSAGE_API_URL = withLocalNetwork(API[ENVIRONMENT].message);

export const UPLOAD_API_URL = withLocalNetwork(API[ENVIRONMENT].upload);

export const PRICE_API_URL = withLocalNetwork(API[ENVIRONMENT].price);

export const GOOGLE_API_KEY = Platform.select({
  default: 'AIzaSyCIR0keQ-WYcXqofaQQgLrrIpMt_OsUaew',
  web: 'AIzaSyBQVYjt93PFnUDn-DIbMIkyydx4Q3AFFpM',
});

export const SENTRY_DSN = Platform.select({
  web: 'https://dc9ad325ea68473e991ec0dcbe4ebf87@o149626.ingest.sentry.io/1197794',
  default:
    'https://62b033cf51b94249ab13555578c8bcbc@o149626.ingest.sentry.io/1199350',
});

export const CARRUM_DIGEST =
  'U2FsdGVkX19CU2IjMHdJ3xBQMYAZln4S+mWScih+tBm1VUFFjVi7jQs/x70mqhci';

export const CDN_URL = 'https://carrum-cdn.s3.amazonaws.com';

export const MINIMUM_PASSWORD_LENGTH = 8;

export const useNativeDriver = Platform.select({ default: true, web: false });

// The average number of milliseconds for a native modal view to dismiss.
export const MODAL_DISMISSAL_TIMEOUT = 280;

export const REQUEST_RECEIVED_TEXT = 'Thank you';

export const PATIENT_FAQ_URL = 'https://info.carrumhealth.com/patient-faq/';

export const PATIENT_SILVER_FAQ_URL =
  'https://info.carrumhealth.com/patient-faq-silver/';

export const SINGLE_CLICK_REGISTRATION = 'singleClickRegistration';

/**
 * Log errors using Sentry when running in non-development mode,
 * including staging and production builds of the web and native
 * app.
 *
 * Explicitly prevent launching in a simulator when running e2
 * tests against the staging environment.
 */
export const SENTRY_ENABLED = ENVIRONMENT !== 'development' && Device.isDevice;
