// @ts-expect-error Cannot find module 'app/assets/images/anonymous.jpg'
export { default as defaultAuthorProfileImage } from 'app/assets/images/anonymous.jpg';

export { EVENTS } from 'app/util/analytics';

export const initialFormValues = { body: '', attachments: [] };

export const messagesPerPage = 20;

// Holiday Scheduling Details:
// Data updated once yearly when the new holiday calendar is released.
export const scheduledHolidays = [
  {
    // thanksgiving
    date: {
      starting: '2024-11-28',
      ending: '2024-11-29',
    },
    messageText:
      'Notice: Holiday hours are in effect until Monday, December 2nd. Responses will resume on that date.',
  },
  {
    // veteran's day
    date: {
      starting: '2024-11-11',
      ending: '2024-11-11',
    },
    messageText:
      'Notice: Holiday hours are in effect until Tuesday, November 12th. Responses will resume on that date.',
  },
  {
    // holiday shutdown
    date: {
      starting: '2024-12-23',
      ending: '2024-12-31',
    },
    messageText:
      'Notice: Holiday hours are in effect until Wednesday, January 1st. Responses will resume on that date.',
  },
];

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';
