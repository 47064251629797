import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-native-elements';
import * as SplashScreen from 'expo-splash-screen';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ThemeProvider as SCThemeProvider } from 'styled-components/native';
import * as Sentry from '@sentry/react-native';
import { Router } from '@cross-platform/react-router-native';

import FocusBlurProvider from 'app/components/Common/FocusBlurProvider';
import ErrorBoundary from 'app/components/Common/ErrorBoundary';
import AppContainer from 'app/containers/AppContainer';
import { ENVIRONMENT, SENTRY_ENABLED } from 'app/util/constants';
import { loadFontsAsync } from 'app/util/fontUtils';
import history from 'app/util/history';
import store from 'app/util/store';
import theme from 'app/util/theme';
import { loadSoundsAsync } from 'app/util/soundUtils';

import 'app/config';

function App() {
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  theme.windowDimensions = useWindowDimensions();
  theme.isNarrow = useWindowDimensions().width <= theme.breakpoints.xsmall;

  /**
   * Render the splash screen until fonts and sounds
   * have been preloaded.
   */
  const loadAssets = async () => {
    await SplashScreen.preventAutoHideAsync();

    try {
      await loadFontsAsync();
      await loadSoundsAsync();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Encountered error loading assets', error);
    } finally {
      setAssetsLoaded(true);
      await SplashScreen.hideAsync();
    }
  };

  useEffect(() => {
    if (!assetsLoaded) loadAssets();
  });

  if (!assetsLoaded) return null;

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <SCThemeProvider theme={theme}>
              <FocusBlurProvider>
                <ErrorBoundary>
                  <AppContainer />
                </ErrorBoundary>
              </FocusBlurProvider>
            </SCThemeProvider>
          </ThemeProvider>
        </Router>
      </Provider>
    </SafeAreaProvider>
  );
}

export default SENTRY_ENABLED ? Sentry.wrap(App) : App;
